import { Input } from "reactstrap";
import { convertToThaana } from "../helpers/ConvertToThaana";
import { createRef, useEffect, useState } from "react";



export const ThaanaInput = (props) => {

    const [value, setValue] = useState(props.value);
    const refInput = createRef();

    const handleChange = (event) => {
        event.target.value = convertToThaana(event.target.value)
        props.onChange(event);
        setValue(event.target.value)
    };

    useEffect(() => {
      if(! props.selectProps.menuIsOpen){
        setValue("")
        props.onBlur()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.selectProps.menuIsOpen])
  
    return (
      <Input ref={refInput} type="text" value={value} className="dv" style={{border:0, height: '1.6em', fontSize: '1em !important'}} onBlur={() => {
        props.selectProps.onMenuClose()
        setValue("")
      }} onClick={() => props.selectProps.menuIsOpen ? props.selectProps.onMenuClose() : props.selectProps.onMenuOpen()} onChange={handleChange} />
    );
};